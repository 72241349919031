/*
 * @Descripttion: 用户信息存储
 * @Author: Dyf
 * @Date: 2023-04-14
 * @LastEditors: Dyf
 * @LastEditTime: 2023-04-14
 */
import { $getUserInfo } from "@api/user";

const state = {
  userInfo: {}, // 用户信息
};

const mutations = {
  setStateNewValue: (state, obj) => {
    state[obj.key] = obj.value;
  },
};

const actions = {
  /** 用户信息 */
  async getUserInfo({ commit }) {
    let { data } = await $getUserInfo();
    await commit("setStateNewValue", {
      key: "userInfo",
      value: data,
    });
    sessionStorage.setItem("schoolId", data.scsch_id);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
