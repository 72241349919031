import axios from "axios";
// import Qs from 'qs
import { Message } from "element-ui";
import { showLoading, hideLoading } from "./loading";
import { API_ROOT, API_ROOT2, REQUEST_TIMEOUT, DEBUG } from "@config";
import router from "@router";

const clearTimeoutByUrl = (url, requestList) => {
  for (let item in requestList) {
    if (url === requestList[item]["url"]) {
      clearTimeout(requestList[item]["timeId"]);
    }
  }
};

/**
 * 创建一个axios实例
 */
const service = axios.create({
  timeout: REQUEST_TIMEOUT, // 请求超时
  baseURL: API_ROOT,
  // withCredentials: true, // 在跨域请求时发送cookie
  /* 请求需要拼接参数 */
  // transformRequest: [
  //     function(data, config) {
  //         return Qs.stringify(data);
  //     },
  // ],
});
service._requestCount = 0; // 累加请求次数
service._requestTimeIdList = [];
/**
 * 请求拦截器
 * 在请求发送之前做什么
 */
service.interceptors.request.use(
  (config) => {
    config.headers = {
      /* 请求参数的格式 */
      "Content-Type": "application/json",
      // "Content-Type": "application/x-www-form-urlencoded",
    };
    if (config.load) {
      const timeId = setTimeout(() => {
        showLoading(config.loadEl || "");
      }, 0);
      service._requestTimeIdList.push({
        timeId: timeId,
        url: config.url,
      });
    }
    if (sessionStorage.getItem("token")) {
      // 让每个请求都带有令牌，请根据实际情况修改
      config.headers["accessToken"] = sessionStorage.getItem("token");
    }
    return config;
  },
  (error) => {
    // 请求错误时做了什么
    if (DEBUG) console.log("requestError：" + error);
    return Promise.reject(error);
  }
);

/**
 * 响应拦截器
 * 如果您想获得http信息，如头信息或状态
 */
service.interceptors.response.use(
  (response) => {
    service._requestCount--;
    clearTimeoutByUrl(response.config.url, service._requestTimeIdList);
    if (service._requestCount <= 0) {
      setTimeout(() => {
        hideLoading(); //加载层清除
      }, 500);
    }
    const res = response.data;
    switch (res.status) {
      case 200:
        if (response.config.responseType === "blob") {
          //文件传输入
          return handleResponse(response);
        } else {
          return res || null;
        }
      case 401:
        Message.error("登录失效，请重新登录！");
        sessionStorage.clear();
        router.replace({ name: "LOGIN" });
        return false;
      default:
        if (res.msg) Message.error(res.msg);
        return false;
    }
  },
  (error) => {
    service._requestCount--;
    let requestList = service._requestTimeIdList;
    for (let item in requestList) {
      clearTimeout(requestList[item]["timeId"]);
    }
    if (service._requestCount <= 0) {
      hideLoading(); //加载层清除
    }
    if (DEBUG) console.log("responseError：" + error);
    Message({
      message: "网络拥挤，请稍后再试~",
      type: "warning",
      duration: 5 * 1000,
    });
    Promise.reject(error);
    return false;
  }
);

/**
 * 处理响应的二进字数据
 * @param {*} response
 */
function handleResponse(response) {
  /**
   * 从响应头获取文件名称
   * 后端返回的文件名称为十六进制
   */
  const fileName = decodeURI(
    response.headers["content-disposition"].split("=")[1]
  );
  try {
    const blob = new Blob([response.data]);
    if ("download" in document.createElement("a")) {
      // 非IE下载
      const elink = document.createElement("a");
      elink.download = fileName;
      elink.style.display = "none";
      elink.href = URL.createObjectURL(blob); //创建下载
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href); // 释放URL 对象
      document.body.removeChild(elink);
    } else {
      // IE10+下载
      navigator.msSaveBlob(blob, fileName);
    }
  } catch (e) {}
}

export default service;
