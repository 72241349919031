/*
 * @Author: Dyf
 * @Date: 2023-02-18 18:25:27
 * @LastEditors: Dyf
 * @LastEditTime: 2023-04-11 09:33:00
 * @Description: 综合分析模块路由
 */
import Layout from "@views/layout"; // 布局

const interactionRouter = {
  path: "/interaction",
  component: Layout,
  name: "INTERACTION",
  meta: { title: "互动中心" },
  redirect: { name: "INTERACTION_INDEX" },
  children: [
    {
      path: "",
      name: "INTERACTION_INDEX",
      meta: { title: "互动中心" },
      component: () => import("@views/interaction/index"),
    },
    {
      path: "message/:tab/:class",
      name: "INTERACTION_MESSAGE",
      meta: { title: "消息" },
      component: () => import("@views/interaction/message"),
    },
    {
      path: "news",
      name: "INTERACTION_NEWS",
      meta: { title: "焦点关注" },
      component: () => import("@views/interaction/news"),
    },
    {
      path: "newsDetail/:id",
      name: "INTERACTION_NEWSDETAIL",
      meta: { title: "焦点关注>详情" },
      component: () => import("@views/interaction/newsDetail"),
    },
  ],
};
export default interactionRouter;
