/*
 * @Author: Dyf
 * @Date: 2023-04-10
 * @LastEditTime: 2023-04-12 09:19:10
 * @Descripttion: 公共模块接口
 */
import HTTP from "@utils/http";
import { PAGE_SIZE } from "@config";

/*******
 * @Descripttion: 获取省市区数据
 * @Author: Dyf
 * @return {*}
 */
export async function $getProvinceData() {
  try {
    return await HTTP.post("/protal/common/get_province_city_area");
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取年级数据
 * @Author: Dyf
 * @return {*}
 */
export async function $getGrade() {
  try {
    return await HTTP.post("/protal/common/grade_data");
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取科目教材数据（树）
 * @Author: Dyf
 * @param {number} grade_id 年级编码
 * @param {number} scsch_id 学校id
 * @return {*}
 */
export async function $getEditionTree(grade_id, scsch_id) {
  try {
    return await HTTP.post("/protal/common/edition_data", {
      grade_id,
      scsch_id,
    });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 省市区下的学校
 * @Author: Dyf
 * @param {Number} params.scsch_province 省
 * @param {Number} params.scsch_city 市
 * @param {Number} params.scsch_area 区
 * @return {*}
 */
export async function $getSchool(params) {
  try {
    return await HTTP.post("/protal/common/get_school", params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取学校下的班级（树）
 * @Author: Dyf
 * @param {number} scsch_id 学校id
 * @return {*}
 */
export async function $getGradeClass(scsch_id) {
  try {
    return await HTTP.post("/protal/common/get_school_class", { scsch_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 上传图片
 * @Author: Dyf
 * @param {file} file 图片文件
 * @return {*}
 */
export async function $uploadImg(file) {
  const formData = new FormData();
  formData.append("file", file, file.name);
  try {
    return await HTTP.post("/protal/tool/upLoadimage", formData);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取题型数据
 * @Author: Dyf
 * @return {*}
 */
export async function $getQuestionType() {
  try {
    return await HTTP.get("/protal/tool/question_type");
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取科目数据
 * @Author: Dyf
 * @return {*}
 */
export async function $getSubject() {
  try {
    return await HTTP.get("/protal/tool/subject");
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取科目相关教材版本
 * @Author: Dyf
 * @param {number} sysub_id 科目id
 * @return {*}
 */
export async function $getSubTextbook(sysub_id) {
  try {
    return await HTTP.post("/protal/tool/edition", { sysub_id });
  } catch (error) {
    return error;
  }
}


/*******
 * @Descripttion: 班级列表
 * @Author: Dyf
 * @return {*}
 */
export async function $getClass() {
  try {
    return await HTTP.post("/teacher/schclass/get_class_list");
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取当前教师下的IM分组成员
 * @Author: LDX
 * @return {*}
 */
export async function $get_im_groupuser(params) {
  try {
    return await HTTP.post("/teacher/schclass/get_im_groupuser", params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 筛选章节信息
 * @Author: Dyf
 * @param {number} params.grade 年级编码
 * @param {number} params.sysub_id 科目id
 * @param {number} params.sysub_idsyedi_id 教材版本id
 * @param {number} params.sysub_idsyedi_version 教材版号
 * @param {number} params.sccou_cha_pid 章id (查询节)
 * @param {string} params.chapter_word 章节关键字
 * @return {*}
 */
export async function $getChapters(params) {
  try {
    return await HTTP.post("/protal/courseschapter/get_chapter", params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取手机验证码
 * @Author: Dyf
 * @param {string} phone 手机号码
 * @return {*}
 */
export async function $getCode(phone) {
  try {
    return await HTTP.post("/protal/common/get_smscaptcha", { phone });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取验证图片组
 * @Author: Dyf
 * @return {*}
 */
export async function $verifyImgs() {
  try {
    return await HTTP.post("protal/common/get_captcha_images");
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 文件下载
 * @Author: Dyf
 * @param {string} filePath 资源文件地址
 * @return {*}
 */
export async function $downloadFile(filePath) {
  try {
    return await HTTP.post("/protal/common/download_file", { filePath });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 操作日志
 * @Author: Dyf
 * @param {number} alog_type 日志类型;1:登录日志；2：操作日志；
 * @param {number} pageindex 页码
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $tea_log(alog_type, pageindex, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post("/teacher/teauserlog/tea_log", { alog_type, pageindex, pagesize });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 导出操作日志
 * @Author: Dyf
 * @return {*}
 */
export async function $export_tealog(alog_type) {
  try {
    return await HTTP.post("/teacher/teauserlog/export_tealog", { alog_type });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion:获取操作手册 
 * @Author: Dyf
 * @param {number} dgi_code 获取端口：TEACHER=教师端，EDUCATION=教育局端，SCHOOL=学校端，STUDENT=学生端，PARENT=家长端
 * @return {*}
 */
export async function $get_manual(dgi_code) {
  try {
    return await HTTP.post("/protal/tool/get_manual", { dgi_code });
  } catch (error) {
    return error;
  }
}

/**
 * @return {*}
 * @author: Dyf
 * @description: 获取服务端AP安装包下载地址
 */
export async function $get_server() {
  try {
    return await HTTP.get("/protal/common/getteacherserver")
  } catch (error) {
    return error;
  }
}