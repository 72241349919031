/*
 * @Descripttion: 个人中心模块接口
 * @Author: Dyf
 * @Date: 2023-04-12 09:23:59
 * @LastEditors: Dyf
 * @LastEditTime: 2023-04-12 09:37:56
 */
import HTTP from "@utils/http";

const USER = "/teacher/teauser/";

/*******
 * @Descripttion: 注册
 * @Author: Dyf
 * @param {*} params
 * @return {*}
 */
export async function $register(params) {
  try {
    return await HTTP.post(`${USER}teacher_register`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 登录
 * @Author: Dyf
 * @param {*} params
 * @return {*}
 */
export async function $login(params) {
  try {
    return await HTTP.post(`${USER}teacher_login`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 忘记/修改密码
 * @Author: Dyf
 * @param {String} params.teuse_phone 手机号
 * @param {String} params.teuse_password 新密码
 * @param {String} params.code 验证码
 * @return {*}
 */
export async function $changePwd(params) {
  try {
    return await HTTP.post(`${USER}change_password`, params);
  } catch (error) {
    return error;
  }
}
/**
 * 忘记密码
 * @param {object} params
 * @returns
 */
export async function $forgotPassword(params) {
  try {
    return await HTTP.post(`${USER}forgot_password`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取个人信息
 * @Author: Dyf
 * @return {*}
 */
export async function $getUserInfo() {
  try {
    return await HTTP.post(`${USER}get_teacher_information`);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 修改个人信息
 * @Author: Dyf
 * @param {String} params
 * @return {*}
 */
export async function $setUserInfo(params) {
  try {
    return await HTTP.post(`${USER}set_teacher_information`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 校验原手机号
 * @Author: Dyf
 * @param {string} code 验证码
 * @param {string} phone 手机号
 * @return {*}
 */
export async function $checkPhone(code, phone) {
  try {
    return await HTTP.post(`${USER}check_phonecode`, { code, phone });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 绑定新手机
 * @Author: Dyf
 * @param {string} code 验证码
 * @param {string} phone 手机号
 * @return {*}
 */
export async function $changePhone(code, phone) {
  try {
    return await HTTP.post(`${USER}change_phone`, { code, phone });
  } catch (error) {
    return error;
  }
}

