import Layout from "@views/layout"; // 布局

const dailyRecordRouter = {
  path: "/dailyRecord",
  component: Layout,
  name: "",
  meta: { title: "日志中心", is_menu: true },
  redirect: { name: "DAILYRECORD" },
  children: [
    {
      path: "",
      name: "DAILYRECORD",
      meta: { title: "日志中心", is_menu: true },
      component: () => import("@views/dailyRecord"),
    },
  ],
};

export default dailyRecordRouter;
