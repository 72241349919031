/*
 * @Author: Dyf
 * @Date: 2023-02-18 18:25:27
 * @LastEditors: Dyf
 * @LastEditTime: 2023-04-03 17:41:55
 * @Description: 作业考试模块路由
 */
import Layout from "@views/layout"; // 布局

const homeworkExamRouter = {
  path: "/he",
  component: Layout,
  name: "HE",
  meta: { title: "作业考试" },
  redirect: { name: "HE_EXAM" },
  children: [
    {
      path: "exam/:class/:id",
      name: "HE_EXAM",
      meta: { title: "校内考试", insertClass: true, keepAlive: true },
      component: () => import("@views/homeworkExam/exam"),
    },
    {
      path: "exam/read/:class/:id/:exam",
      name: "HE_EXAMEREAD",
      meta: { title: "批阅", insertClass: true, pTitle: "校内考试" },
      component: () => import("@views/homeworkExam/exameRead"),
    },
    {
      path: "homework/:class/:id",
      name: "HE_HOMEWORK",
      meta: { title: "家庭作业", insertClass: true, keepAlive: true },
      component: () => import("@views/homeworkExam/homework"),
    },
    {
      path: "homework/read/:class/:id/:homework",
      name: "HE_HOMEWORKREAD",
      meta: { title: "批阅", insertClass: true, pTitle: "家庭作业" },
      component: () => import("@views/homeworkExam/homeworkRead"),
    },
    {
      path: "schoolwork/:class/:id",
      name: "HE_SCHOOLWORK",
      meta: { title: "校内作业", insertClass: true, keepAlive: true },
      component: () => import("@views/homeworkExam/schoolwork"),
    },
    {
      path: "schoolwork/read/:class/:id/:homework",
      name: "HE_SCHOOLWORKREAD",
      meta: { title: "批阅", insertClass: true, pTitle: "校内作业" },
      component: () => import("@views/homeworkExam/schoolworkRead"),
    },
    {
      path: "schoolwork/distribute/:class/:id/:homework/:sccla_id/:tecla_id",
      name: "HE_SCHOOLWORKDISTRIBUTE",
      meta: { title: "下发作业", insertClass: true, pTitle: "校内作业" },
      component: () => import("@views/homeworkExam/distribute"),
    },
    {
      path: "practicework/:class/:id",
      name: "HE_PRACTICEWORK",
      meta: { title: "课堂练习", insertClass: true, keepAlive: true },
      component: () => import("@views/homeworkExam/practicework"),
    },
    {
      path: "practicework/read/:class/:id/:tecla",
      name: "HE_PRACTICEWORKREAD",
      meta: { title: "批阅", insertClass: true, pTitle: "课堂练习" },
      component: () => import("@views/homeworkExam/practiceworkRead"),
    },
    {
      path: "question/bank",
      name: "HE_QUESTIONBANK",
      meta: { title: "校内题库>题库中心" },
      component: () => import("@views/homeworkExam/questionBank"),
    },
    {
      path: "question/list/:from/:id",
      name: "HE_QUESTIONLIST",
      meta: { title: "校内题库>题库中心" },
      component: () => import("@views/homeworkExam/questionList"),
      beforeEnter: (to, from, next) => {
        let title = "";
        switch (to.params.from) {
          case 2:
            title = "校内题库>题库中心>校本库";
            break;
          case 3:
            title = "校内题库>题库中心>平台库";
            break;
          default:
            title = "校内题库>题库中心>我的库";
            break;
        }
        to.meta.title = title;
        return next();
      },
    },
    {
      path: "exampapers",
      name: "HE_EXAMPAPERS",
      meta: { title: "校内题库>试卷管理" },
      component: () => import("@views/homeworkExam/examPapers"),
    },
    {
      path: "exampapers/add",
      name: "HE_EXAMPAPERADD",
      meta: { title: "校内题库>试卷管理>新增" },
      component: () => import("@views/homeworkExam/examPaperModify"),
    },
    {
      path: "exampapers/detail/:id/:type",
      name: "HE_EXAMPAPERDETAIL",
      meta: { title: "校内题库>试卷管理>详情" },
      component: () => import("@views/homeworkExam/examPaperDetail"),
    },
    {
      path: "exampapers/modify/:id",
      name: "HE_EXAMPAPERMODIFY",
      meta: { title: "校内题库>试卷管理>详情>编辑" },
      component: () => import("@views/homeworkExam/examPaperModify"),
    },
  ],
};
export default homeworkExamRouter;
