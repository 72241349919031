/*
 * @Author: Dyf
 * @Date: 2023-02-18 18:25:27
 * @LastEditors: Dyf
 * @LastEditTime: 2023-04-10 15:13:22
 * @Description: 综合分析模块路由
 */
import Layout from "@views/layout"; // 布局

const analysisRouter = {
  path: "/analysis",
  component: Layout,
  name: "ANALYSIS",
  meta: { title: "综合分析" },
  redirect: { name: "ANALYSIS_SYNTHESIS" },
  children: [
    {
      path: "synthesis/:class/:id",
      name: "ANALYSIS_SYNTHESIS",
      meta: { title: "综合分析", insertClass: true },
      component: () => import("@views/analysis/synthesis"),
    },
    {
      path: "wrongQuestions/:class/:id",
      name: "ANALYSIS_WRONGQUESTIONS",
      meta: { title: "错题总数 ", insertClass: true, pTitle: "综合分析" },
      component: () => import("@views/analysis/wrongQuestions"),
    },
    {
      path: "doneWrongQuestions/:class/:id",
      name: "ANALYSIS_DONEWRONGQUESTIONS",
      meta: { title: "已重做错题总数 ", insertClass: true, pTitle: "综合分析" },
      component: () => import("@views/analysis/wrongQuestions"),
    },
  ],
};
export default analysisRouter;
