/*
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-29 09:54:52
 * @LastEditTime: 2023-03-29 09:59:13
 * @Descripttion: 教学模块路由
 */
import Layout from "@views/layout"; // 布局

const teachingRouter = {
  path: "/teaching",
  component: Layout,
  name: "TEACHING",
  meta: { title: "校内教学" },
  redirect: { name: "TEACHING_PREPARE" },
  children: [
    {
      path: "prepare",
      name: "TEACHING_PREPARE",
      meta: { title: "校内课程备课" },
      component: () => import("@views/teaching/prepare"),
    },
    {
      path: "instruct",
      name: "TEACHING_INSTRUCT",
      meta: { title: "校内授课" },
      component: () => import("@views/teaching/instruct"),
    },
    {
      path: "instruct",
      name: "TEACHING_INSTRUCT",
      meta: { title: "校内授课" },
      component: () => import("@views/teaching/instruct"),
    },
    {
      path: "attendClass/:id/:sccla_id",
      name: "TEACHING_ATTENDCLASS",
      meta: { title: "校内授课>上课" },
      component: () => import("@views/teaching/attendClass"),
    },
    {
      path: "courseware",
      name: "TEACHING_COURSEWARE",
      meta: { title: "校内资源" },
      component: () => import("@views/teaching/courseware"),
    },
    {
      path: "courseware/detail/:id/:type",
      name: "TEACHING_COURSEWARE_DETAIL",
      meta: { title: "校内资源>详情" },
      component: () => import("@views/teaching/coursewareDetail"),
    },
  ],
};
export default teachingRouter;
