<!--
 * @Author: Dyf
 * @Date: 2023-02-01
 * @LastEditors: Dyf
 * @LastEditTime: 2023-07-06 11:31
 * @Description: 
-->
<style lang="scss" scoped>
.layout {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #e8eaf3;
  position: relative;
  box-sizing: border-box;
  padding: 2.5vh 0;

  &-container {
    width: 100%;
    height: 100%;
    position: relative;
  }
}
</style>

<template>
  <section class="layout">
    <!-- 侧边导航 -->
    <sidebar />
    <div class="layout-container">
      <!-- 头部 -->
      <!-- <navbar /> -->
      <!-- 主体部分 -->
      <app-main />
    </div>
  </section>
</template>

<script>
import AppMain from "./components/AppMain";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import { mapActions } from "vuex"

export default {
  name: "Layout",
  components: {
    AppMain,
    Navbar,
    Sidebar,
  },

  computed: {},
  created() {
    this.getUserInfo();
    this.initCommon();
  },
  methods: {
    ...mapActions('common', ['initCommon']),
    ...mapActions('user', ['getUserInfo']),
  },
};
</script>
