var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{ref:"sidebar",staticClass:"sidebar"},[_c('div',{staticClass:"user",on:{"click":function($event){return _vm.$router.push({ name: 'HOME_PERSONALCENTER' })}}},[_c('p',{staticClass:"avatar"},[_c('el-avatar',{attrs:{"fit":"cover","size":65,"src":_vm.formatfile(_vm.userInfo.teuse_image)}},[_c('img',{attrs:{"src":require("@assets/images/empty_avatar.png")}})])],1),_c('h5',{staticClass:"bold line-text--1st"},[_vm._v(_vm._s(_vm.userInfo.teuse_name))])]),_c('div',{ref:"menu",staticClass:"swiper menu"},[_c('div',{staticClass:"swiper-wrapper"},[_vm._l((_vm.menuList),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide menu-fir",class:{ current: _vm.menuFirIndex == index, hide: item.hide },on:{"mouseenter":function($event){return _vm.mouseOver(index)}}},[(!item._children)?_c('router-link',{staticClass:"title link",attrs:{"to":{ name: item.routerName }}},[(_vm.chatNumber > 0 && item.title == '互动中心')?_c('div',{staticClass:"dian"},[_vm._v(" "+_vm._s(_vm.chatNumber)+" ")]):_vm._e(),_c('i',{domProps:{"innerHTML":_vm._s(item.icon)}}),_c('p',[_vm._v(_vm._s(item.title))])]):[_c('div',{staticClass:"title"},[(_vm.chatNumber > 0 && item.title == '互动中心')?_c('div',{staticClass:"dian"},[_vm._v(" "+_vm._s(_vm.chatNumber)+" ")]):_vm._e(),_c('i',{domProps:{"innerHTML":_vm._s(item.icon)}}),_c('p',[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"menu-sec-box",on:{"mouseenter":_vm.menuSecHover,"mouseleave":_vm.menuSecLeave}},[_c('ul',{staticClass:"menu-sec"},_vm._l((item._children),function(secitem,secindex){return _c('li',{key:secindex,staticClass:"sec-item",class:{
                  current: _vm.menuSecIndex == secindex && _vm.menuFirIndex == index,
                  hide: secitem.hide,
                }},[(!secitem._children)?_c('p',{staticClass:"sec-item--title",on:{"click":function($event){return _vm.routeTap(secitem, item)}}},[_vm._v(" "+_vm._s(secitem.title)+" ")]):[_c('p',{staticClass:"sec-item--title",on:{"click":function($event){$event.stopPropagation();return _vm.checkMenuSec(secindex, index)}}},[_c('span',[_vm._v(_vm._s(secitem.title))]),_c('i',{staticClass:"iconfont"},[_vm._v("")])]),_c('ul',{staticClass:"menu-third",class:{
                      show: _vm.menuFirIndex == index && _vm.menuSecIndex == secindex,
                    }},_vm._l((secitem._children),function(thirditem,thirdindex){return _c('li',{key:thirdindex,staticClass:"third-item",class:{
                        current:
                          _vm.menuThirdIndex == thirdindex &&
                          _vm.menuSecIndex == secindex &&
                          _vm.menuFirIndex == index,
                      },on:{"click":function($event){(_vm.menuThirdIndex = thirdindex),
                          _vm.routeTap(thirditem, secitem)}}},[_c('p',{staticClass:"third-item--title"},[_vm._v(_vm._s(thirditem.title))])])}),0)]],2)}),0)])]],2)}),_c('div',{staticClass:"swiper-slide menu-fir"},[_c('a',{staticClass:"title link",attrs:{"target":"_blank","href":_vm.dgi_value}},[_c('i',{staticClass:"iconfont"},[_vm._v("")]),_c('p',[_vm._v("操作文档")])])]),_c('div',{staticClass:"swiper-slide login-out iconfont",on:{"click":_vm.loginOut}},[_vm._v("  ")])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }