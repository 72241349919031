<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-02-24 10:32:32
 * @LastEditTime: 2023-03-21 18:06:42
 * @Descripttion: 
-->
<style lang="scss" scoped>
.navbar {
  width: 100%;
  height: 162px;
  box-sizing: border-box;
  padding-left: 144px;
  margin: 0 auto;
  box-sizing: border-box;
  border-bottom: 1px solid;
}
</style>

<template>
  <section class="navbar" v-if="!isHome">

  </section>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      isHome: true,
      breadList: [],
      schoolDialog: false,
    };
  },
  watch: {
    $route: {
      handler(route) {
        let list = route.matched;
        this.getBreadcrumb(list);
      },
      immediate: true,
    },
  },
  created() { },
  methods: {
    /** 判断是否是首页 */
    pageHome(route) {
      return route.name == "HOME" || route.name == "HOME_INDEX";
    },
    /** 获取面包屑数据 */
    getBreadcrumb(matched) {
      matched = this.$_.unionBy(matched, "meta.title");
      /* 如果不是首页 */
      if (!this.pageHome(matched[matched.length-1])) {
        matched = [].concat(matched);
        this.isHome = false;
      } else {
        this.isHome = true;
      }
      this.breadList = matched;
    },
  },
};
</script>
