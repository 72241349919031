/*
 * @Author: Dyf
 * @Date: 2023-02-18 18:25:27
 * @LastEditors: Dyf
 * @LastEditTime: 2023-03-22 18:20:59
 * @Description: 首页模块路由
 */
import Layout from "@views/layout"; // 布局

const homeRouter = {
  path: "/",
  component: Layout,
  name: "HOME",
  meta: { title: "首页" },
  redirect: { name: "HOME_INDEX" },
  children: [
    {
      path: "home",
      name: "HOME_INDEX",
      meta: { title: "首页" },
      component: () => import("@views/home/index"),
    },
    {
      path: "home/timetable",
      name: "HOME_TIMETABLE",
      meta: { title: "课程表时间设置" },
      component: () => import("@views/home/timetable"),
    },
    {
      path: "home/lessonSet/:grade/:class",
      name: "HOME_LESSONSET",
      meta: { title: "课程表设置" },
      component: () => import("@views/home/lessonSet"),
    },
    {
      path: "home/textbook",
      name: "HOME_TEXTBOOK",
      meta: { title: "教材管理" },
      component: () => import("@views/home/textbook"),
    },
    {
      path: "home/textbook/chapters/:id",
      name: "HOME_CHAPTERS",
      meta: { title: "教材管理>章节管理" },
      component: () => import("@views/home/chapters"),
    },
    {
      path: "home/textbook/checkChapters/:id",
      name: "HOME_CHECKCHAPTERS",
      meta: { title: "教材管理>查看章节" },
      component: () => import("@views/home/checkChapters"),
    },
    {
      path: "home/classesGrades",
      name: "HOME_CLASSESGRADES",
      meta: { title: "班级管理" },
      component: () => import("@views/home/classesGrades"),
    },
    {
      path: "home/teachingClass/:grade/:class",
      name: "HOME_TEACHINGCLASS",
      meta: { title: "班级管理" },
      component: () => import("@views/home/teachingClass"),
    },
    {
      path: "home/studentTransfer",
      name: "HOME_STUDENTTRANSFER",
      meta: { title: "转入处理" },
      component: () => import("@views/home/studentTransfer"),
    },
    {
      path: "home/personalCenter",
      name: "HOME_PERSONALCENTER",
      meta: { title: "个人中心" },
      component: () => import("@views/home/personalCenter"),
    },
    {
      path: "home/studyAnalysis:grade:class",
      name: "HOME_STUDYANALYSIS",
      meta: { title: "学情分析" },
      component: () => import("@views/home/studyAnalysis"),
    },
    {
      path: "home/notify",
      name: "HOME_NOTIFY",
      meta: { title: "通知公告" },
      component: () => import("@views/home/notify"),
    },
    {
      path: "home/notify/detail/:id",
      name: "HOME_NOTIFYDETAIL",
      meta: { title: "通知公告>详情" },
      component: () => import("@views/home/notifyDetail"),
    },
  ],
};

export default homeRouter;
