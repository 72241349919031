/*
 * @Author: Dyf
 * @Date: 2023-02-18 18:25:27
 * @LastEditors: Dyf
 * @LastEditTime: 2023-03-22 18:20:59
 * @Description: 首页模块路由
 */
import Layout from "@views/layout"; // 布局

const publicClassRouter = {
  path: "/publicClass",
  component: Layout,
  name: "PUBLIC_CLASS",
  meta: { title: "公开课" },
  redirect: { name: "PUBLIC_CLASS_LIST" },
  children: [
    {
      path: "",
      name: "PUBLIC_CLASS_LIST",
      meta: { title: "公开课" },
      component: () => import("@views/publicClass"),
    },
    {
      path: "/detail/:id",
      name: "PUBLIC_CLASS_DETAIL",
      meta: { title: "详情" },
      component: () => import("@views/publicClassDetail"),
    },
  ],
};

export default publicClassRouter;
