/*
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-02-01 09:56:55
 * @LastEditTime: 2023-03-22 18:35:54
 * @Descripttion:
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Message } from "element-ui";
import { API_ROOT } from "@config";

import "normalize.css/normalize.css"; // 替代CSS重置
import "@utils/element-ues"; // 按需引入ElementUI,若有缺少，自行添加
import "@assets/styles/element-variables.scss";
import "@assets/styles/public.scss"; // 全局引用样式
import "./permission"; // 权限控制
import _ from "lodash"; // lodash工具库
import dayjs from "dayjs"; // dayjs时间管理工具
import * as filters from "@/filters"; // 全局过滤器
import * as directives from "./directives"; // 全局过指令
import * as echarts from "echarts"; //echarts图表

/* 百度编辑器 */
import "../lib/ueditor/ueditor.config.js";
import "../lib/ueditor/ueditor.all.min.js";
import "../lib/ueditor/lang/zh-cn/zh-cn.js";
import "../lib/ueditor/ueditor.parse.min.js";

/* 视频播放器 */
import videoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
Vue.use(videoPlayer);

/* 全局引用自定义组件 */
import breadcrumb from "@comp/breadcrumb";
Vue.component("breadcrumb", breadcrumb);

/* 全局引用自定义组件 */
import richSelect from "@comp/richSelect";
Vue.component("richSelect", richSelect);

// lodash挂载
Vue.prototype.$echarts = echarts; // echarts图表
Vue.prototype.$_ = _; // lodash工具库
Vue.prototype.$day = dayjs; // dayjs时间管理工具]

/* 判断数据是否存在、是否为空（空对象 || 空数组） */
Vue.prototype.$isEmpty = function (data) {
  if (!data) return true;
  return (
    (data instanceof Array && !data.length) ||
    Object.keys(data).length == 0 ||
    data + "" == ""
  );
};


// 处理自定义组件全局注册
const files = require.context('./components/try', true, /\.vue$/)
files.keys().forEach((routerPath) => {
  const componentName = routerPath.replace(/^\.\/(.*)\/index\.\w+$/, '$1')
  if (componentName.indexOf('/') === -1) {
    const value = files(routerPath)
    Vue.component('try' + componentName.replace(/([A-Z])/g, '-$1').toLowerCase(), value.default)
   // console.log('try' + componentName.replace(/([A-Z])/g, '-$1').toLowerCase());
  }
}, {});


/* 上传配置 */
Vue.prototype.$upload = {
  header: { accessToken: sessionStorage.getItem("token") },
  imgAction: `${API_ROOT}/protal/tool/upLoadimage`, // 图片上传地址
  imgAccept: ".jpg,.jpeg,.png",
  fileAction: `${API_ROOT}/protal/tool/uploadFile`, // 文件上传地址
  fileAction2: `${API_ROOT}/protal/tool/inportFile`, // 文件上传地址
};

/* 图片上传前处理 */
Vue.prototype.$beforImgUpload = (file) => {
  const imgType = ["jpg", "jpeg", "png"]; // 图片文件类型
  let fileType = file.name.split(".");
  fileType = fileType[fileType.length - 1];
  const isImg = imgType.indexOf(fileType) != -1;
  const isSize = file.size / 1024 / 1024 < 10;
  if (!isImg) {
    Message.error("请上传规定的图片格式（.jpg,.jpeg,.png）");
    return false;
  }
  if (!isSize) {
    Message.error("上传图片大小不能超过 10MB!");
    return false;
  }

  return isImg && isSize;
};

/* 视频上传前处理 */
Vue.prototype.$beforVideoUpload = (file) => {
  const videoType = ["mp4", "avi", "wmv", "mpeg"]; // 视频文件类型
  let fileType = file.name.split(".");
  fileType = fileType[fileType.length - 1];
  const isVideo = videoType.indexOf(fileType) != -1;
  const isSize = file.size / 1024 / 1024 / 1024 < 3;
  if (!isVideo) {
    Message.error("请上传规定的视频格式（.mp4,.avi,.wmv,.mpeg");
    return false;
  }
  if (!isSize) {
    Message.error("上传视频大小不能超过 3G!");
    return false;
  }
  return isVideo && isSize;
};

/* 文件上传前处理 */
Vue.prototype.$beforFileUpload = (file, fileTypes) => {
  const videoType = ["mp4", "avi", "wmv", "mpeg"]; // 视频文件类型
  let fileType = file.name.split(".");
  fileType = fileType[fileType.length - 1];
  const isRightFormat = fileTypes.indexOf(fileType) != -1;
  const isVideo = videoType.indexOf(fileType) != -1;
  const isSize = file.size / 1024 / 1024 < 200; // 其他文件大小
  const isVideoSize = file.size / 1024 / 1024 / 1024 < 3; // 视频文件大小

  if (!isRightFormat) {
    let typeMes = fileTypes.map((item) => `.${item}`);
    Message.error(`请上传规定的文件格式（${typeMes.join(",")}）`);
    return false;
  }
  if (isVideo && !isVideoSize) {
    Message.error(`上传视频大小不能超过 3G!`);
    return false;
  }
  if (!isVideo && !isSize) {
    Message.error(`上传文件大小不能超过 200MB!`);
    return false;
  }
  return (isVideo && isVideoSize) || (!isVideo && isSize);
};

/* 文件链接下载 */
Vue.prototype.$fileDownload = (url) => {
  let name = url.split("/");
  name = name[name.length - 1].split(".")[0];
  // 创建一个a标签
  let link = document.createElement("a");
  // 给a标签设置id
  link.id = "downloadLink";
  // 将a标签隐藏
  link.style.display = "none";
  // 给a标签添加下载链接
  link.href = url;
  // 给a标签添加一个download属性
  link.setAttribute("download", name);
  // 将创建的a标签加入到body的尾部
  document.body.appendChild(link);
  // 执行a标签
  link.click();
  // 获取a标签dom
  let node = document.getElementById("downloadLink");
  // 删除对应dom节点
  document.body.removeChild(node);
};

/* 路由缓存处理 */
Vue.prototype.$routeAlive = (to, from, next, children_routes) => {
  if (children_routes.indexOf(to.name) != -1) {
    from.meta.keepAlive = true;
  } else {
    from.meta.keepAlive = false;
  }
  next();
};

/* 注册全局过滤器 */
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

/* 注册全局过指令 */
Object.keys(directives).forEach((key) => {
  Vue.use(directives[key]);
});

/* el-select 滚动加载更多 */
Vue.directive("loadMore", {
  bind(el, binding) {
    // 获取element，定义scroll
    let select_dom = el.querySelector(
      ".el-select-dropdown .el-select-dropdown__wrap"
    );
    select_dom.addEventListener("scroll", function () {
      let height = this.scrollHeight - this.scrollTop <= this.clientHeight;
      if (height) {
        binding.value();
      }
    });
  },
});

Vue.config.productionTip = false;

// im聊天
// 从v2.11.2起，SDK 支持了 WebSocket，推荐接入；v2.10.2及以下版本，使用 HTTP
// v2.24.0起，SDK 支持使用本地审核插件
import TIM from "tim-js-sdk";
import TIMUploadPlugin from "tim-upload-plugin";
// import TIMProfanityFilterPlugin from 'tim-profanity-filter-plugin';

let options = {
  SDKAppID: 1400814172, // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
};
// 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
let tim = TIM.create(options); // SDK 实例通常用 tim 表示

// 设置 SDK 日志输出级别，详细分级请参见 setLogLevel https://web.sdk.qcloud.com/im/doc/zh-cn/SDK.html#setLogLevel 接口的说明</a>
tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
// tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用

// 注册腾讯云即时通信 IM 上传插件
tim.registerPlugin({ "tim-upload-plugin": TIMUploadPlugin });

// 注册腾讯云即时通信 IM 本地审核插件
// tim.registerPlugin({'tim-profanity-filter-plugin': TIMProfanityFilterPlugin});

//挂载到全局
Vue.prototype.tim = tim;
Vue.prototype.TIM = TIM;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
